<template>
  <div>
    <div
      :style="`background-image: url(./../../imgs/home/bg_section_1.jpg)`"
      class="content__section_1"
    >
      <v-container>
        <HeaderComponent />
        <!-- <v-row class="ma-0">
          <v-col cols="6">
            <iframe
              id="yourIframeId"
              src="https://meiaentradadigital.com.br/student-card/henriquetroiano@hoomweb.com/UBRDCEbXyvLAUVCEtOHm5l1IW4sNgrkEMq6t"
              width="100%"
              height="10px"
              :style="{
                border: 'none',
              }"
            />
          </v-col>
        </v-row> -->
        <div class="center">
          <h3 class="porque_ter_carteirinha">
            Por que ter uma carteirinha de estudante?
          </h3>
        </div>
        <SplideSlider
          :options="{ rewind: true }"
          class="slides_splides display_block_mobile"
        >
          <SplideSlide class="slide_splide">
            <div class="center">
              <div class="card_porque_carteirinha">
                <img src="imgs/home/item_1.png" />
                <span class="spacer"></span>
                <p class="porque_subititulo">
                  <span class="porque_subititulo_bold"
                    >Acesso a descontos em vários produtos e serviços, </span
                  >economizando dinheiro.
                </p>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide class="slide_splide">
            <div class="card_porque_carteirinha">
              <div class="card_porque_carteirinha">
                <img src="imgs/home/item_2.png" />
                <span class="spacer"></span>
                <p class="porque_subititulo">
                  <span class="porque_subititulo_bold">Fácil de usar e segura: </span
                  >Tenha sempre no seu smartphone, mais prático e seguro.
                </p>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide class="slide_splide">
            <div class="card_porque_carteirinha">
              <div class="card_porque_carteirinha">
                <img src="imgs/home/item_3.png" />
                <span class="spacer"></span>
                <p class="porque_subititulo">Aprovação rápida, prazo de 2 a 48 horas.</p>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide class="slide_splide">
            <div class="card_porque_carteirinha">
              <div class="card_porque_carteirinha">
                <img src="imgs/home/item_4.png" />
                <span class="spacer"></span>
                <p class="porque_subititulo">
                  Garanta tudo isso com a autêntica carteirinha de estudante!
                </p>
              </div>
            </div>
          </SplideSlide>
        </SplideSlider>
        <div class="beetween display_none_mobile">
          <div class="card_porque_carteirinha">
            <img src="imgs/home/item_1.png" />
            <span class="spacer"></span>
            <p class="porque_subititulo">
              <span class="porque_subititulo_bold"
                >Acesso a descontos em vários produtos e serviços, </span
              >economizando dinheiro.
            </p>
          </div>
          <div class="card_porque_carteirinha">
            <img src="imgs/home/item_2.png" />
            <span class="spacer"></span>
            <p class="porque_subititulo">
              <span class="porque_subititulo_bold">Fácil de usar e segura: </span>Tenha
              sempre no seu smartphone, mais prático e seguro.
            </p>
          </div>
          <div class="card_porque_carteirinha">
            <img src="imgs/home/item_3.png" />
            <span class="spacer"></span>
            <p class="porque_subititulo">
              Tenha sempre no seu smartphone, mais prático e seguro.
            </p>
          </div>
          <div class="card_porque_carteirinha">
            <img src="imgs/home/item_4.png" />
            <span class="spacer"></span>
            <p class="porque_subititulo">
              Garanta tudo isso com a autêntica carteirinha de estudante!
            </p>
          </div>
        </div>

        <v-row class="ma-0">
          <v-col
            class="content__items text__section"
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order="2"
            order-sm="2"
            order-md="2"
            order-lg="1"
            order-xl="1"
          >
            <div class="banner">
              <div class="banner_txt">
                <p class="titulo_banner">
                  Mais que uma carteirinha, um mundo de benefícios!
                </p>
                <p class="observacao_banner">
                  Compre agora e obtenha todos os benefícios da lei de meia entrada:
                </p>
                <v-form v-model="form" ref="form" id="faca_sua">
                  <div class="form">
                    <v-text-field
                      id="formRegisterName"
                      dark
                      v-model="name"
                      label="Nome da pessoa"
                      :rules="[(v) => !!v || 'Campo Obrigatório']"
                      required
                    ></v-text-field>

                    <v-text-field
                      id="formRegisterEmail"
                      dark
                      label="E-mail"
                      v-model="email"
                      :rules="[
                        (v) => !!v || 'Campo obrigatório',
                        (v) => isValidEmail(v) || 'E-mail Inválido!',
                      ]"
                    ></v-text-field>
                    <v-text-field
                      id="formRegisterDocument"
                      dark
                      v-mask="documentMask"
                      v-model="document"
                      :counter="14"
                      label="CPF"
                      hint="000.000.000-00"
                      placeholder="000.000.000-00"
                      :rules="[
                        (v) => !!v || 'Campo Obrigatório',
                        (v) =>
                          validateDocument(v) || 'Não parece um CPF ou CNPJ Inválido',
                      ]"
                      required
                    ></v-text-field>
                    <div class="campo_flex">
                      <v-text-field
                        id="formRegisterPhone"
                        dark
                        v-model="phone"
                        label="Telefone (Whatsapp) para contato"
                        v-mask="phoneMask"
                        :rules="[validatePhone]"
                        required
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      <v-checkbox
                        dark
                        v-model="couponCheck"
                        :label="`Têm Cupom?`"
                      ></v-checkbox>
                    </div>

                    <v-row class="ma-0" align="center" v-if="couponCheck">
                      <v-text-field
                        :disabled="!!coupon"
                        dark
                        v-model="couponCode"
                        label="Digite o Cupom"
                        @blur="checkCoupon(couponCode, true)"
                        :append-icon="coupon ? '' : 'mdi-magnify'"
                        @click:append="checkCoupon(couponCode, true)"
                        @keypress.prevent.capture.enter="checkCoupon(couponCode, true)"
                        required
                        :loading="loading"
                      ></v-text-field>
                      <v-btn
                        v-if="coupon"
                        class="ml-2"
                        dark
                        small
                        color="red"
                        @click="
                          coupon = null;
                          couponCode = null;
                        "
                        >Remover Cupom</v-btn
                      >
                    </v-row>

                    <v-btn
                      class="btn_prosseguir"
                      type="submit"
                      :loading="loadingCreate"
                      @click.prevent="facaCarteirinha()"
                    >
                      <span class="centrarlizar_btn">
                        <p class="prosseguir">Faça sua carteirinha agora</p>
                      </span>
                    </v-btn>

                    <p class="paragrafo">
                      Após a confirmação dos dados, você será redirecionado para o
                      pagamento com desconto. Ao continuar, você concorda com os a nossa
                      <a
                        :href="privacyPolicyURL"
                        target="_blank"
                        style="color: unset; text-decoration: underline"
                        >Política de Privacidade e Termos de Uso</a
                      >
                    </p>
                  </div>
                </v-form>
              </div>
            </div>
          </v-col>
          <v-col
            class="image__section"
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order="1"
            order-sm="1"
            order-md="1"
            order-lg="2"
            order-xl="2"
          >
            <v-progress-linear
              v-if="loadingGetDomain"
              indeterminate
              color="white"
            ></v-progress-linear>
            <RegularPriceBanner
              v-else
              :loading="loadingGetDomain"
              :domain="domain"
              :customPrice="finalValue != currentValue ? finalValue : null"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Sobre Nós -->
    <div class="bg">
      <v-container>
        <v-row class="ma-0 invert_row">
          <v-col
            class="image__section"
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order="1"
            order-sm="1"
            order-md="1"
            order-lg="2"
            order-xl="2"
          >
            <v-responsive :aspect-ratio="1">
              <div class="content__price">
                <div
                  class="content__student__card__about"
                  :style="`background-image: url(./../../imgs/about/phone_card.png)`"
                >
                  <v-responsive :aspect-ratio="1.33 / 1"></v-responsive>
                </div>
              </div>
            </v-responsive>
          </v-col>
          <v-col
            class="content__items text__section"
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            order="2"
            order-sm="2"
            order-md="2"
            order-lg="1"
            order-xl="1"
          >
            <h3 class="titulo_sobre">Sobre nós</h3>
            <p class="paragrafo_sobre">
              Somos uma plataforma tecnológica de suporte às entidades que desejam inovar
              com segurança, praticidade e tecnologia na versão da Carteira Digital,
              oferecendo diversas vantagens ao publico estudantil, da educação básica ao
              ensino superior.
            </p>
            <p class="paragrafo_sobre">
              É com essa aspiração que queremos estar no smartphone de cada um dos
              estudantes, podendo levar facilidade e praticidade de utilização nos
              estabelecimentos conveniados e em nossa rede de parceiros.
            </p>
            <p class="paragrafo_sobre">
              Desta forma o Meia Entrada Digital se presta a dar suporte para que
              Entidades Estudantis em sua missão possam com tranquilidade e segurança
              emitir a Carteira Digital. Esperamos você para inovar conosco!
            </p>
            <a href="/#carteirinha" class="link">
              <v-btn class="btn_prosseguir" type="submit">
                <span class="centrarlizar_btn">
                  <p class="prosseguir">Faça sua carteirinha agora</p>
                </span>
              </v-btn>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div
      class="content__section_2"
      :style="`background-image: url(./../../imgs/home/bg_section_2.jpg)`"
    >
      <v-container>
        <v-row class="ma-0">
          <v-col
            cols="6"
            sm="6"
            md="6"
            lg="4"
            xl="4"
            order="2"
            order-sm="2"
            order-md="2"
            order-lg="1"
            order-xl="1"
          >
            <v-responsive :aspect-ratio="1 / 1.7" style="overflow: visible">
              <div
                class="phone__section first__phone"
                :style="`background-image: url(./../../imgs/home/phone_home_1.png)`"
              ></div>
            </v-responsive>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="4"
            xl="4"
            order="1"
            order-sm="1"
            order-md="1"
            order-lg="2"
            order-xl="2"
          >
            <div class="content__infos">
              <h3>Baixe agora e aproveite!</h3>
              <div class="icons__section">
                <a
                  href="https://itunes.apple.com/br/app/id1670378560?l=pt&mt=8"
                  target="_blank"
                  class="icon__item"
                >
                  <v-responsive :aspect-ratio="16 / 5">
                    <div
                      class="image__item"
                      :style="`background-image: url(./../../imgs/btns/btn_apple.png)`"
                    ></div>
                  </v-responsive>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.meiaentradadigital&hl=pt_BR"
                  target="_blank"
                  class="icon__item"
                >
                  <v-responsive :aspect-ratio="16 / 5">
                    <div
                      class="image__item"
                      :style="`background-image: url(./../../imgs/btns/btn_google_play.png)`"
                    ></div>
                  </v-responsive>
                </a>
              </div>
              <h4>Você consegue descontos<br />em diversas áreas</h4>
              <div class="icons__discounts__section">
                <v-row class="ma-0">
                  <v-col
                    cols="6"
                    sm="6"
                    md="3"
                    lg="4"
                    xl="4"
                    v-for="(item, i) in 7"
                    :key="i"
                  >
                    <v-responsive :aspect-ratio="1" style="overflow: visible">
                      <div
                        class="item__icon__discount"
                        :style="`background-image: url(./../../imgs/home/icon_${item}.png)`"
                      ></div>
                    </v-responsive>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="6"
            lg="4"
            xl="4"
            order="3"
            order-sm="3"
            order-md="3"
            order-lg="3"
            order-xl="3"
          >
            <v-responsive :aspect-ratio="1 / 1.7">
              <div
                class="phone__section second__phone"
                :style="`background-image: url(./../../imgs/home/phone_home_2.png)`"
              ></div>
            </v-responsive>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container class="pa-0">
      <DialogPix :value="dialogPix" @close="dialogPix = false" />
    </v-container>
    <div class="bg">
      <v-container>
        <div class="center">
          <div class="titulo_lembre">
            <h1 class="titulo_lembre_text">Lembre-se</h1>
          </div>
        </div>
        <div class="center">
          <div class="paragrafo_lembre">
            <p class="paragrafo_lembre_1">
              Para ter garantido o benefício da meia-entrada é preciso ter a CIE (Carteira
              de Identificação Estudantil) nacionalmente padronizada.
            </p>
            <p class="paragrafo_lembre_2">
              O uso de uma carteira de estudante falsa pode levar a pessoa a até cinco
              anos de prisão. Um crime que pode parecer uma leve infração, na realidade se
              constitui como um crime grave de falsidade ideológica e de estelionato.
            </p>
          </div>
        </div>
        <div class="center">
          <div class="conter">
            <h2 class="conter_titulo">A carteirinha de meia-entrada deve conter:</h2>
            <div
              class="img_carteirinha_info"
              :style="`background-image: url(./../../imgs/home/card_info.png)`"
            >
              <v-responsive :aspect-ratio="1.33 / 1"></v-responsive>
            </div>
          </div>
        </div>

        <div class="center">
          <h2 class="solicite_titulo">Solicite a sua agora! É rápido e fácil.</h2>
        </div>
        <div class="center">
          <div class="solicite_cards">
            <div class="card_solicite">
              <div class="img_icone_solicite">
                <div :style="`background-image: url(./../../imgs/icones/form.png)`">
                  <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                </div>
              </div>
              <p class="text_card_solicite">
                1- Preencha o formulário, seguindo as instruções
              </p>
            </div>

            <div class="card_solicite">
              <div class="img_icone_solicite">
                <div :style="`background-image: url(./../../imgs/icones/cifrao.png)`">
                  <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                </div>
              </div>
              <p class="text_card_solicite">2- Faça o pagamento de sua carteirinha</p>
            </div>

            <div class="card_solicite">
              <div class="img_icone_solicite">
                <div :style="`background-image: url(./../../imgs/icones/download.png)`">
                  <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                </div>
              </div>
              <p class="text_card_solicite">
                3- Baixe o app do Meia Entrada Digital e usufrua de seus benefícios!
              </p>
            </div>
          </div>
        </div>
        <div class="center">
          <a href="/#carteirinha" class="link">
            <button class="btn btn_peca_carteirinha">
              <p class="btn_peca_txt">Peça a sua carteirinha agora</p>
            </button>
          </a>
        </div>

        <div class="acao">
          <p class="paragrafo_acao">
            Cada ação da ABE é milimetricamente pensada, objetivando sempre favorecer o
            aluno. Por isso, além de adquirir a Carteirinha oficial de Estudante para
            desfrutar de todos os benefícios garantidos por Lei, oferecemos o que outras
            empresas do mercado não oferecem:
          </p>
          <div class="cards_acao">
            <div class="card_acao">
              <p class="titulo_card_acao">Bolsas de Estudo</p>
              <p class="paragrafo_card_acao">
                Bolsas de estudos para diversos cursos técnicos, graduação e
                pós-graduação.
              </p>
            </div>
            <div class="card_acao">
              <p class="titulo_card_acao">Planos de Seguro</p>
              <p class="paragrafo_card_acao">
                Descontos e facilidades em seguros de celular, seguro de vida, de
                automóvel entre outros
              </p>
            </div>
          </div>

          <div class="cards_acao">
            <div class="card_acao_2">
              <p class="titulo_card_acao">Benefícios no Plano de Saúde</p>
              <p class="paragrafo_card_acao">
                Descontos e facilidades para planos de saúde.
              </p>
            </div>
            <div class="card_acao_2">
              <p class="titulo_card_acao">Orientação Jurídica</p>
              <p class="paragrafo_card_acao">Oferecemos orientação jurídica para você.</p>
            </div>
          </div>

          <div class="cards_acao">
            <div class="card_acao_2">
              <p class="titulo_card_acao">Benefícios no Plano Odontológico</p>
              <p class="paragrafo_card_acao">
                Descontos e facilidades para planos odontológicos.
              </p>
            </div>
            <div class="card_acao_2">
              <p class="titulo_card_acao">Apoio Educacional</p>
              <p class="paragrafo_card_acao">
                Estamos aqui para te apoiar na sua formação.
              </p>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <div class="bg">
      <v-container>
        <div class="center" id="faq">
          <h1 class="titulo_perguntas">Perguntas frequentes</h1>
        </div>
        <div class="center">
          <div class="perguntas_frequentes">
            <a
              class="btn_pergunta link"
              data-toggle="collapse"
              href="#pergunta_1"
              role="button"
              aria-expanded="false"
              aria-controls="pergunta_1"
              @click="trocarIconeAdd(1)"
            >
              <div class="card_pergunta">
                <p class="txt_pergunta">
                  Quais são os modelos de carteirinha de meia-entrada da Meia Entrada
                  Digital?
                </p>
                <div class="icone_add" :style="icone_add">
                  <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                </div>
              </div>
            </a>
            <div class="collapse btn_resposta" id="pergunta_1">
              <p class="txt_resposta">
                Oferecemos apenas a Carteirinha Digital, modelo online, onde você poderá
                acessar através do aplicativo da Meia Entrada Digital.
              </p>
            </div>
          </div>

          <div class="perguntas_frequentes">
            <a
              class="btn_pergunta link"
              data-toggle="collapse"
              href="#pergunta_2"
              role="button"
              aria-expanded="false"
              aria-controls="pergunta_2"
              @click="trocarIconeAdd(2)"
            >
              <div class="card_pergunta">
                <p class="txt_pergunta">
                  Se eu fizer a Carteirinha Digital, eu também tenho acesso à Carteirinha
                  Física?
                </p>
                <div class="icone_add" :style="icone_2">
                  <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                </div>
              </div>
            </a>
            <div class="collapse btn_resposta" id="pergunta_2">
              <p class="txt_resposta">
                Não, a Meia Entrada Digital produz apenas as Carteirinha no modelo
                digital.
              </p>
            </div>
          </div>

          <div class="perguntas_frequentes">
            <a
              class="btn_pergunta link"
              data-toggle="collapse"
              href="#pergunta_3"
              role="button"
              aria-expanded="false"
              aria-controls="pergunta_3"
              @click="trocarIconeAdd(3)"
            >
              <div class="card_pergunta">
                <p class="txt_pergunta">Quais são os métodos de pagamento?</p>
                <div class="icone_add" :style="icone_3">
                  <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                </div>
              </div>
            </a>
            <div class="collapse btn_resposta" id="pergunta_3">
              <p class="txt_resposta">
                Aceitamos pagamentos via PIX. O pagamento é totalmente seguro e confiável.
              </p>
            </div>
          </div>

          <div class="perguntas_frequentes">
            <a
              class="btn_pergunta link"
              data-toggle="collapse"
              href="#pergunta_4"
              role="button"
              aria-expanded="false"
              aria-controls="pergunta_4"
              @click="trocarIconeAdd(4)"
            >
              <div class="card_pergunta">
                <p class="txt_pergunta">Preciso enviar alguma documentação?</p>
                <div class="icone_add" :style="icone_4">
                  <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                </div>
              </div>
            </a>
            <div class="collapse btn_resposta" id="pergunta_4">
              <p class="txt_resposta">
                Sim. Foto (self), foto da identidade e comprovante de matrícula
                atualizado. Toda a segurança que você precisa para ter a sua carteirinha
                sempre com você.
              </p>
            </div>
          </div>

          <div class="perguntas_frequentes">
            <a
              class="btn_pergunta link"
              data-toggle="collapse"
              href="#pergunta_5"
              role="button"
              aria-expanded="false"
              aria-controls="pergunta_5"
              @click="trocarIconeAdd(5)"
            >
              <div class="card_pergunta">
                <p class="txt_pergunta">Qual é o tempo de espera?</p>
                <div class="icone_add" :style="icone_5">
                  <v-responsive :aspect-ratio="1 / 1"></v-responsive>
                </div>
              </div>
            </a>
            <div class="collapse btn_resposta" id="pergunta_5">
              <p class="txt_resposta">
                Carteirinha Digital, após o pagamento e o envio dos documentos, a
                aprovação ocorre em até 24h.
              </p>
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <div class="bg">
      <v-container>
        <div class="center">
          <h3 class="txt_meia_entrada">
            Meia-entrada só com a Meia Entrada Digital. A carteira oficial! Não fique de
            fora.
          </h3>
          <h5 class="txt_whats">
            Precisa falar com a gente? Entre em contato no nosso WhatsApp:
          </h5>
          <h1 class="telefone_meia_entrada">(11) 93908-1255</h1>
        </div>
      </v-container>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import "@splidejs/splide/dist/css/splide.min.css";
import axios from "axios";
import { cpf } from "cpf-cnpj-validator";
import { VueMaskDirective } from "v-mask";
import { phone } from "phone";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
/* eslint-disable */
import * as EmailValidator from "email-validator";

export default {
  name: "HomePage",
  props: {},
  components: {
    SplideSlider: Splide,
    SplideSlide,
    EmailValidator,
    HeaderComponent: () => import("./../components/HeaderComponent"),
    FooterComponent: () => import("./../components/FooterComponent"),
    DialogPix: () => import("./../components/DialogPixForm"),
    RegularPriceBanner: () => import("./../components/RegularPriceBanner"),
  },
  data() {
    return {
      privacyPolicyURL: process.env.VUE_APP_API + "/privacy-policy",
      icone_add_1: "url(./../../imgs/icones/add.png)",
      icone_add_2: "url(./../../imgs/icones/add.png)",
      icone_add_3: "url(./../../imgs/icones/add.png)",
      icone_add_4: "url(./../../imgs/icones/add.png)",
      icone_add_5: "url(./../../imgs/icones/add.png)",
      control_1: false,
      control_2: false,
      control_3: false,
      control_4: false,
      control_5: false,
      loading: false,
      loadingCreate: false,
      loadingRecovery: false,
      loadingPatner: false,
      loadingGetDomain: false,
      domain: null,
      coupon: null,
      dialogPix: false,
      id: 0,
      estudent: null,
      couponCode: null,
      couponCheck: false,
      pixItem: [],
      name: null,
      phone: null,
      email: null,
      document: null,
      form: null,
      rating: [],
      ratingCinema: {
        range: 0,
      },
      ratingShow: {
        range: 0,
      },
      ratingTeatro: {
        range: 0,
      },
      ratingEventos: {
        range: 0,
      },
      ratingCursos: {
        range: 0,
      },
      noticiasItems: [],
      parceirosItems: [],
      valorEconomizaMes: 0,
      valorEconomizaAno: 0,
      options: {
        autoplay: true,
        interval: 1000,
        perPage: 2,
        // rewind: true,
        // heightRatio: 0.5,
        arrows: false,
        breakpoints: {
          5000: {
            heightRatio: 0.25,
          },
          2559: {
            heightRatio: 0.4,
          },
          1300: {
            heightRatio: 0.5,
          },
          1000: {
            heightRatio: 1,
          },
        },
      },
      phoneMask: "(##) ####-#####",
      totalHours: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  directives: {
    mask: VueMaskDirective,
  },
  computed: {
    originalValue() {
      return this.domain?.from_price ?? 49.9;
    },
    currentValue() {
      return this.domain?.price ?? 34.9;
    },
    couponDiscountValue() {
      if (this.coupon) {
        if (this.coupon.type == "percent") {
          //Descobre o valor da porcentagem para subtrair no final
          let discountValue, x;

          x = this.coupon.value / 100;
          discountValue = this.currentValue * x;

          return discountValue;
        } else {
          return this.coupon.value; //
        }
      }
      return 0;
    },
    finalValue() {
      return this.currentValue - this.couponDiscountValue;
    },
    documentMask() {
      let id = this.data?.identification;
      let mask = this.getMask(id);
      return mask;
    },
    icone_add() {
      return {
        "background-image": this.icone_add_1,
      };
    },
    icone_2() {
      return {
        "background-image": this.icone_add_2,
      };
    },
    icone_3() {
      return {
        "background-image": this.icone_add_3,
      };
    },
    icone_4() {
      return {
        "background-image": this.icone_add_4,
      };
    },
    icone_5() {
      return {
        "background-image": this.icone_add_5,
      };
    },
  },
  watch: {
    couponCode() {
      this.coupon = null;
    },
    phone: function (v) {
      setTimeout(() => {
        if (!v) {
          this.phoneMask = "(##) ####-#####";
        }
        v = `${v}`.replace(/\D/g, "");
        this.phoneMask = v.length <= 10 ? "(##) ####-#####" : "(##) #####-####";
      }, 250);
    },
  },
  mounted() {
    this.estudent = JSON.parse(localStorage.getItem("estudent"));
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
    // window.addEventListener(
    //   "message",
    //   (event) => {
    //     // Verificar a origem da mensagem
    //     // if (event.origin !== 'https://meiaentradadigital.com.br') return; //opcional

    //     if (event.data.type && event.data.type === "aspectRatio") {
    //       var aspectRatio = event.data.value;
    //       console.log({ aspectRatio });

    //       // Redimensionar o iframe com base na proporção recebida
    //       var iframe = document.getElementById("yourIframeId");
    //       if (iframe) {
    //         var newHeight = iframe.offsetWidth / aspectRatio;
    //         iframe.style.height = newHeight + "px";
    //       }
    //     }
    //   },
    //   false
    // );
  },
  created() {
    this.getDomain();
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const midnight = new Date();
      midnight.setHours(24, 0, 0, 0);

      const timeDifference = midnight - now;
      if (timeDifference > 0) {
        this.hours = String(Math.floor(timeDifference / (1000 * 60 * 60))).padStart(
          2,
          "0"
        );
        this.minutes = String(Math.floor((timeDifference / (1000 * 60)) % 60)).padStart(
          2,
          "0"
        );
        this.seconds = String(Math.floor((timeDifference / 1000) % 60)).padStart(2, "0");
      } else {
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
      }
    },
    validatePhone(v) {
      if (!v) {
        return "Telefone Inválido!";
      }
      v = v.replace(/\D/g, "");
      if (v.length < 10) {
        return "Telefone Inválido!";
      }
      if (v.length == 10) {
        return "Precisamos de um número Whatsapp. Enviaremos sua senha provisória para ele e também por e-mail";
      }
      const check = phone(v, {
        country: "BR",
        validateMobilePrefix: true,
      });
      return check.isValid ? true : "Telefone Inválido";
    },
    trocarIconeAdd(id) {
      switch (id) {
        case 1:
          if (this.control_1 == false) {
            this.control_1 = true;
            this.icone_add_1 = "url(./../../imgs/icones/remove.png)";
          } else {
            this.icone_add_1 = "url(./../../imgs/icones/add.png)";
            this.control_1 = false;
          }
          break;
        case 2:
          if (this.control_2 == false) {
            this.control_2 = true;
            this.icone_add_2 = "url(./../../imgs/icones/remove.png)";
          } else {
            this.icone_add_2 = "url(./../../imgs/icones/add.png)";
            this.control_2 = false;
          }
          break;
        case 3:
          if (this.control_3 == false) {
            this.control_3 = true;
            this.icone_add_3 = "url(./../../imgs/icones/remove.png)";
          } else {
            this.icone_add_3 = "url(./../../imgs/icones/add.png)";
            this.control_3 = false;
          }
          break;
        case 4:
          if (this.control_4 == false) {
            this.control_4 = true;
            this.icone_add_4 = "url(./../../imgs/icones/remove.png)";
          } else {
            this.icone_add_4 = "url(./../../imgs/icones/add.png)";
            this.control_4 = false;
          }
          break;
        case 5:
          if (this.control_5 == false) {
            this.control_5 = true;
            this.icone_add_5 = "url(./../../imgs/icones/remove.png)";
          } else {
            this.icone_add_5 = "url(./../../imgs/icones/add.png)";
            this.control_5 = false;
          }
          break;
        default:
      }
    },
    getFontSizePrice(percentage) {
      let height = this.$refs.price?.clientHeight;
      if (!height || !percentage) return 16;
      return (height / 100) * percentage;
    },
    async getDomain() {
      this.loadingGetDomain = true;
      let domain = window.location.origin;
      if (`${domain}`.includes("www.")) {
        domain = domain.replaceAll("www.", "");
      }
      await this.$http
        .get(process.env.VUE_APP_PAYMENT_API + `/api/partner-domain?url=${domain}`)
        .then((r) => {
          console.log({ r });
          this.loadingGetDomain = false;
          this.domain = r?.data?.data;
        })
        .catch((e) => {
          this.loadingGetDomain = false;
          console.log(e.response);
        });
      this.loadingGetDomain = false;
    },
    async checkCoupon(couponCode, emmitErrors) {
      if (this.coupon?.coupon == couponCode) return;
      if (this.loading) return;
      if (!couponCode) {
        return this.$notify({
          group: "app",
          type: "error",
          title: "Cupom inválido",
          text: "Insira um código de cupom",
        });
      }
      this.loading = true;
      var params = {
        coupon: couponCode,
      };
      await this.$http
        .post("/partner/coupons/check", params)
        .then((r) => {
          this.loading = false;
          this.coupon = r?.data?.coupon;
          this.$notify({
            group: "app",
            type: "success",
            title: "Cupom aplicado.",
            text: r?.data?.message || "Cupom válido e aplicado.",
          });
        })
        .catch((e) => {
          this.loading = false;
          if (emmitErrors) {
            this.$notify({
              group: "app",
              type: "error",
              title: "Cupom inválido",
              text: e.response?.data?.message || "Tente novamente mais tarde",
            });
          }
        });
      this.loading = false;
    },
    formatReais(reais) {
      reais = (reais / 100).toFixed(2) + "";
      reais = reais.replace(".", ",");
      reais = reais.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return reais;
    },
    formatDate(date) {
      let dateStr = date.split("T")[0];
      date = new Date(dateStr).toLocaleDateString("pt-br", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return date;
    },
    atualizaValor() {
      // Definer valor ... nao adicionar virgula e nem ponto para os sentavos.
      let valorIngresso = 5071; // 5071 é = 50 reais e 71 centavos
      let valorShows = 1505;
      let valorTeatro = 2503;
      let valorEventos = 20031;
      let valorCursos = 10032;

      let valor =
        this.ratingCinema.range * valorIngresso +
        this.ratingShow.range * valorShows +
        this.ratingTeatro.range * valorTeatro +
        this.ratingEventos.range * valorEventos +
        this.ratingCursos.range * valorCursos;

      this.valorEconomizaMes = valor;

      this.valorEconomizaAno = 12 * valor;
    },
    addRatingCinema(value) {
      value = value - 1;
      this.ratingCinema.range = value;
      this.atualizaValor();
    },
    addRatingShow(value) {
      value = value - 1;
      this.ratingShow.range = value;
      this.atualizaValor();
    },
    addRatingTeatro(value) {
      value = value - 1;
      this.ratingTeatro.range = value;
      this.atualizaValor();
    },
    addRatingEventos(value) {
      value = value - 1;
      this.ratingEventos.range = value;
      this.atualizaValor();
    },
    addRatingCursos(value) {
      value = value - 1;
      this.ratingCursos.range = value;
      this.atualizaValor();
    },
    isValidEmail(email) {
      return EmailValidator.validate(email);
    },
    validateDocument(v) {
      return cpf.isValid(v);
    },
    getMask() {
      let cpfMask = "###.###.###-##";
      return cpfMask;
    },
    openPix() {
      this.dialogPix = true;
      this.dialog = true;
    },
    onClosePix() {
      this.dialogPix = false;
    },
    async facaCarteirinha() {
      //validação de campos
      if (!this.$refs.form?.validate()) {
        alert(
          "Preencha os campos do formulario. Eles são obrigatórios para seu contato ser enviado. "
        );
        return;
      }

      const instance = axios.create({
        baseURL: process.env.VUE_APP_PAYMENT_API,
        headers: {
          Accept: "application/json",
          clientId: "clientIDTeste",
          clientSecret: "clientSecret",
        },
      });

      //verifica Se Tem Cadastro de Usuario
      var verificaSeTemCadastroUsuario = false;
      this.loadingCreate = true;
      var params = {
        name: this.name,
        email: this.email,
        document: this.document,
        phone: this.phone,
        coupon: this.coupon?.coupon,
      };

      await this.$http
        .post("/partner/estudents/exists", params)
        .then((r) => {
          if (r?.data.success == true) {
            verificaSeTemCadastroUsuario = true;
          }
          this.loadingCreate = false;
        })
        .catch((e) => {
          this.loadingCreate = false;
        });
      this.loadingCreate = false;
      if (verificaSeTemCadastroUsuario == true) {
        alert(
          `Este e-mail já está em uso. Se esqueceu sua senha, recupere o acesso pelo "esqueci minha senha" no APP ou entre em contato com o suporte pelo Whatsapp.`
        );
        return;
      }
      let method = instance.post;
      let url = "/api/customers";
      //verifica Se Tem Cadastro de Lead
      await instance
        .get(`/customers/by-email/${this.email}`, params, {
          headers: {
            Accept: "application/json",
            clientId: "clientIDTeste",
            clientSecret: "clientSecret",
            Authorization: `Bearer ${null}`,
          },
        })
        .then((r) => {
          if (r?.data?.id) {
            method = instance.put;
            url = `/api/customers/${r?.data?.id}`;
          }
          this.loadingCreate = false;
        })
        .catch((e) => {
          this.loadingCreate = false;
        });
      this.loadingCreate = false;

      await method(url, params)
        .then((r) => {
          localStorage.setItem("estudent", JSON.stringify(r?.data));
          this.loadingCreate = false;
          this.openPix();
        })
        .catch((e) => {
          this.loadingCreate = false;
          this.$notify({
            group: "app",
            type: "error",
            title: "Ocorreu um erro",
            text: e.response?.data?.message || "Tente novamente mais tarde",
          });
        });
      this.loadingCreate = false;
    },
    async getDomainConfig() {
      const origin = window.location.origin;
      try {
        const domain = await this.$http.get(
          process.env.VUE_APP_PAYMENT_API + `/api/partner-domain?url=${origin}`
        );
        if (domain.data) {
          this.loadingPatner = false;
          this.currentValue = domain?.data.price;
          this.finalValue = domain?.data.price;
        }
      } catch (e) {
        console.error(e);
        alert(`Erro ao buscar configurações do domínio: ${e.response.data}`);
      }
    },
  },
};
</script>

<style scoped>
.beetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}
.card_porque_carteirinha {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  /* background-color: #fa9500; */
  margin: 5px 0;
}
.card_porque_carteirinha img {
  height: 230px;
}
.porque_ter_carteirinha {
  color: var(--White, #fff);
  text-align: center;
  font-family: yummo, sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.porque_subititulo {
  color: var(--White, #fff);
  text-align: center;
  font-family: yummo, sans-serif;
  font-size: 23px;
  line-height: normal;
  font-weight: 100;
  padding: 0;
  margin: 0;
  height: 120px;
  /* font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400; */
}
.porque_subititulo_bold {
  font-family: "yummo_bold";
}
.campo_flex {
  display: flex;
}
.content__items {
  backdrop-filter: blur(20px);
}
.paragrafo {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
.banner {
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner_txt {
  width: 100%;
  padding: 1rem;
}
.banner_img {
  width: 10rem;
  max-width: 95vw;
  height: max-content;
}

.content__contagem_regressiva {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 130px;
  top: 0%;
  left: 23%;
  /* background-color: #ffffff; */
  background-size: contain;
  background-position: center;
}

.content__app_img {
  position: absolute;
  z-index: 0;
  width: 100%;
  top: 28%;
  left: -32%;
  background-size: contain;
  background-position: center;
}

.content__downloads {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 80px;
  bottom: 0%;
  left: 10%;
  background-size: contain;
  background-position: center;
}
.card_download {
  display: flex;
}
.download {
  display: flex;
}
.card_download {
  width: 300px;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #043980;
  padding: 0.1rem 1rem;
}
.txt_download {
  display: flex;
  flex-direction: column;
}
.dado_principal_card_donwload {
  padding: 0;
  margin: 0;
  font-family: "yummo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  /* line-height: 44px; */
  color: #ffffff;
}
.descricao_card_download {
  padding: 0;
  margin: 0;
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.container_contagem {
  padding: 0 5px;
  width: 260px;
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subtitulo_contagem {
  color: red;
  font-family: "yummo", sans-serif;
  font-style: normal;
  font-weight: 1000;
  font-size: 10px;
  padding: 0 0px 0px;
  text-align: center;
}
.titulo_contagem {
  color: red;
  padding: 10px 0 0 0;
  margin: 0 0 -10px 0;
  font-family: "yummo", sans-serif;
  font-style: normal;
  font-weight: 1000;
  font-size: 17px;
}
.info_contagem {
  display: flex;
  color: red;
  padding: 0;
  margin: 0;
  font-family: "yummo", sans-serif;
  font-size: 45px;
}
.info_contagem_detalhado {
  font-size: 15px;
  margin: -10px 0 10px 0;
}
.txt_time {
  color: #fff;
  font-size: 9px;
}

.content__price {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.circle__section {
  position: relative;
  z-index: 1;
  left: 25%;
  top: 25%;
}

.content__price .circle__section {
  width: 40%;
  background-size: contain;
  background-position: center;
  user-select: none;
}
.content__price .circle__section .price__items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: 0.5s !important;
}
.content__price .circle__section .price__items * {
  color: #072d61;
  letter-spacing: 0;
  font-family: yummo, sans-serif;
  line-height: 1;
  margin: unset;
  white-space: nowrap;
}
.content__price .circle__section .price__items .from__price {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  padding-left: 15%;
}
.content__price .circle__section .price__items .from__price p {
  font-weight: bolder;
  position: relative;
}
.content__price .circle__section .price__items .from__price p::after {
  content: " ";
  position: absolute;
  left: -7%;
  right: -7%;
  top: 40%;
  height: 20%;
  width: 114%;
  background-color: red;
  transform: rotate(-18deg);
  border-radius: 3600px;
}
.content__price .circle__section .price__items .from__price span {
  transform: translateY(-25%);
}

.content__price .circle__section .price__items .to__price {
  display: flex;
  align-items: flex-start;
}

.content__price .circle__section .price__items .to__price .price__item__effective {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.content__price .circle__section .price__items .to__price .price__item__effective p {
  font-weight: bold;
}
.content__price .circle__section .price__items .to__price .price__item__effective span {
  transform: translateY(-50%);
}

.content__price .circle__section .price__items .to__price span {
  transform: translateY(50%);
}

.content__student__card__example {
  position: absolute;
  z-index: 3;
  width: 75%;
  top: 45%;
  left: 25%;
  background-size: contain;
  background-position: center;
}

.titulo_banner {
  font-size: 44px;
  line-height: 54px;
  color: #ffffff;
}
.subtitulo_banner {
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
}
.observacao_banner {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.div_img_stars {
  position: absolute;
  z-index: 1;
}
.preco_star {
  position: absolute;
  z-index: 2;
  margin: 60px 0 0 30px;
  background-size: contain;
  background-position: center;
}
.img_card {
  position: relative;
  z-index: 2;
  margin: 65px 0 0px 30px;
}
.efeito_preco {
  position: absolute;
  margin: -44px 0 0 20px;
}
.efeito_preco img {
  width: 80px;
}
.preco {
  display: flex;
  align-items: baseline;
}
.preco_antigo {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #043980;
}
.valor_antigo {
  font-size: 30px;
  line-height: 35px;
  color: #043980;
}
.valor_novo {
  font-size: 55px;
  line-height: 35px;
  color: #043980;
}
.cifrao {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  color: #043980;
}
.input_cpf {
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.btn_prosseguir {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  margin: 25px 0;
  gap: 8px;
  width: 100%;
  height: 46px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 0.5rem;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.prosseguir {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
/* section 2 */
.bg_fantasma {
  position: absolute;
  width: 90000vw;
  left: -500vw;
  z-index: 0;
  height: 1780px;
  background-color: #fff;
}
.bg_section {
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  padding: 20px 15px;
  /* height: 1780px; */
  border-radius: 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.div_celular {
  display: flex;
  text-align: center;
}
.div_img_celular {
  width: 460px;
  margin: 50px 70px 0 0;
}
.div_img_celular img {
  position: relative;
}
.efeito_celular {
  position: absolute;
  margin: 100px 0 0 80px;
}
.div_texto_celular {
  margin: 70px 0 0 0;
}

.div_texto_celular {
  padding: 0 0 0 50px;
}
.txt_celular {
  font-size: 40px;
  line-height: 54px;
  color: #043980;
  text-align: start;
}
.div_flex {
  margin: 50px 0;
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.txt_icon {
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  color: #043980;
  padding: 0 15px;
}
.hidden {
  width: 80px;
  visibility: hidden;
}
.btn_app {
  margin: 25px 0;
}
.img_google_btn {
  padding: 0 30px;
}
/* Noticias */
.txt_destaque {
  font-size: 44px;
  line-height: 54px;
  color: #043980;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.paragrafo_destaque {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #043980;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.div_noticias {
  display: flex;
  justify-content: space-between;
}
.noticia_principal {
  /* background-color: #043980; */
  width: 570px;
  height: 400px;
  display: flex;
  align-items: center;
}
.tag_noticia {
  width: 121px;
  height: 25px;
  background: linear-gradient(70.92deg, #043980 47.78%, #29b5a2 101.48%);
  border: 1px solid #1c4173;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt_lancamento {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.titulo_noticia_principal {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
}
.detalhes_noticia_principal {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.card_noticia_principal {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 570px;
  height: 400px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 20px 15px 20px;
}
.noticia_secundaria {
  width: 570px;
  height: 400px;
  margin-left: 20px;
  /* background-color: #806d04; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.titulo_noticia_secundaria {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}
.detalhes_noticia_secundaria {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.data_noticia_principal {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #dddddd;
}
.data_noticia_secundaria {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #494949;
}
.card_noticia_secundaria {
  display: flex;
}
.img_card_noticia_secundaria {
  width: 190px;
  height: 190px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 1rem;
  margin-right: 15px;
}
.txt_card_noticia_secundaria {
  width: 380px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
/* Simulação */
.simulacao {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.simulacao_funcionalidade {
  /* background-color: #1b8004; */
  width: 570px;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rating {
  width: 570px;
  display: flex;
  justify-content: space-between;
}
.pergunta_simulacao {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #043980;
}
.div_numeracao {
  width: 570px;
  display: flex;
  justify-content: space-between;
}
.numeracao {
  padding: 0 20px;

  font-size: 23px;
  line-height: 28px;
  text-align: center;
  color: #043980;
}
.simulacao_resultado {
  width: 570px;
  height: 625px;
  margin-left: 2px;
  /* background-color: #806d04; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.titulo_simule {
  font-size: 55px;
  line-height: 67px;
  color: #043980;
}
.card_simule {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px 0px;
  background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
  height: 280px;
  border-radius: 20px;
}
.txt_economiza {
  padding: 0;
  margin: 0;

  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #043980;
}
.valor_economizaria,
.valor_economiza {
  display: flex;
  align-items: center;
}
.cifrao_economiza {
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #043980;
}
.valor_soma_economiza {
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #043980;
}
.txt_economizaria {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #043980;
  padding: 0;
  margin: 0;
}
.cifrao_economizaria {
  font-size: 69px;
  line-height: 74px;
  text-align: center;
  color: #043980;
}
.valor_soma_economizaria {
  font-size: 69px;
  line-height: 74px;
  text-align: center;
  color: #043980;
}
.obs {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #043980;
}
/* section 3 */
.carrosel_parceiros {
  height: 400px;
  margin: 25px 0;
}
.txt_parceiros {
  font-size: 44px;
  line-height: 54px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
}
.carrosel {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slides_splides {
  width: 1200px;
  height: 250px;
  border-radius: 1rem;
}
.slide_splide {
  display: flex;
  justify-content: center;
}
.card_slide_splide {
  padding: 20px;
  width: 600px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  /* background: #ffffff; */
  border-radius: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.phone__section {
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
}
.phone__section.first__phone {
  transform: scale(1.3);
}

.content__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content__infos h3 {
  margin-top: 3rem;
  color: white;
  font-family: yummo, sans-serif;
  font-size: 2.2rem;
  text-align: center;
}

.content__infos h4 {
  color: white;
  font-family: yummo, sans-serif;
  font-size: 1.5rem;
  text-align: center;
}
.content__infos .icons__section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.content__infos .icons__section .icon__item {
  width: 45%;
  margin: 0.8rem;
}
.content__infos .icons__section .icon__item .image__item {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
}

.content__infos .icons__discounts__section {
  width: 100%;
  padding: 0 2rem;
}

.content__infos .icons__discounts__section .item__icon__discount {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
}

.content__infos .icons__discounts__section .item__icon__discount:hover {
  transform: scale(1.2) rotate(-15deg);
}
/* Sobre nós */
.invert_row {
  display: flex;
  flex-direction: row-reverse;
}
.bg {
  margin-top: -2px;
  padding: 0;
  background-color: #043980;
}
.titulo_sobre {
  font-family: "Yummo";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 66px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.paragrafo_sobre {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.content__student__card__about {
  position: absolute;
  left: 15%;
  background-size: contain;
  background-position: center;
  width: 85%;
  top: 17%;
}
.center {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
}
.titulo_lembre {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  height: 42px;
  background: #e7ac00;
}
.titulo_lembre_text {
  font-family: "Yummo";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  height: 34px;
  color: #043980;
}
.paragrafo_lembre {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 768px;
}
.paragrafo_lembre_1 {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding: 0;
  margin: 0;
}
.paragrafo_lembre_2 {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.conter_titulo {
  font-family: "Yummo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #ffffff;
  margin: 30px 0;
}
.solicite_titulo {
  font-family: "Yummo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #ffffff;
}
.img_carteirinha_info {
  background-position: center;
}
.solicite_cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 966px;
  padding: 0px;
  gap: 32px;
}
.card_solicite {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 24px;
  width: 275.67px;
  height: 264px;
  border: 1px solid #e7ac00;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.img_icone_solicite {
  width: 100px;
  height: 100px;
}
.img_icone_solicite div {
  background-position: center;
}
.text_card_solicite {
  width: 268.67px;
  height: 44px;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
.btn_peca_carteirinha {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 100%;
  width: 966px;
  height: 46px;
  background: #e7ac00;
  border: 1px solid #e7ac00;
  border-radius: 10px;
}
.btn_peca_txt {
  width: 235px;
  height: 22px;
  margin: 0;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}

.acao {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;
}

.paragrafo_acao {
  /* width: 100%; */
  width: 966px;
  height: 66px;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.cards_acao {
  display: flex;
  justify-content: space-between;
  width: 966px;
  align-items: center;
}

.card_acao_2,
.card_acao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 8px 8px 12px;
  gap: 20px;
  isolation: isolate;
  width: 467.5px;
  height: 78px;
  border: 1px solid #ffc316;
  border-radius: 10px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.card_acao_2 {
  height: 58px;
}

.titulo_card_acao {
  position: absolute;
  margin: -30px 0 0 0;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffc316;
  background-color: #043980;
  padding: 0 10px 0 5px;
}
.paragrafo_card_acao {
  width: 447.5px;
  height: 44px;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.icone_add {
  width: 20px;
}
.card_pergunta {
  display: flex;
  justify-content: space-between;
  width: 966px;
}
.titulo_perguntas {
  font-family: "Yummo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: white;
}
.txt_pergunta {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.txt_resposta {
  font-family: "Libre Franklin";
  width: 966px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.txt_meia_entrada {
  width: 724.43px;
  font-family: "Yummo";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0 0 20px 0;
}
.txt_whats {
  width: 581px;
  height: 25px;
  font-family: "Yummo";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.telefone_meia_entrada {
  width: 256px;
  height: 43px;
  font-family: "Yummo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 50px;
}
.display_block_mobile {
  display: none;
}

@media screen and (orientation: portrait), (max-width: 1000px) {
  .display_none_mobile {
    display: none;
  }
  .display_block_mobile {
    display: block;
  }

  .porque_ter_carteirinha {
    font-size: 1.5rem;
  }

  .card_porque_carteirinha img {
    height: auto;
    width: 30vw;
  }
  .card_porque_carteirinha {
    width: 250px;
  }
  .porque_subititulo {
    font-size: 20px;
  }
  .porque_subititulo {
    height: auto;
  }
  .img_economize img {
    width: 80vw;
  }
  .economize {
    margin: 25px 0;
  }
  .content__student__card__example {
    left: 15%;
    width: 91%;
    top: 39%;
  }

  .container {
    padding: 0 12px !important;
  }
  .image__section {
    /* background-color: yellow; */
    height: 500px;
    display: flex;
    /* align-items: stretch; */
  }
  .content__price .circle__section .price__items {
    /* background-color: #ff8008; */
    padding-left: 10px;
  }
  .circle__section {
    left: 100px;
    /* width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important; */
    /* background-color: green; */
  }

  .content__contagem_regressiva {
    /* background-color: blue; */
    width: auto;
    left: 0;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .container_contagem {
    width: auto !important;
    margin: 0 !important;
  }
  /* .content__app_img {
    background-color: red;
  } */
  .content__student__card__example {
    margin-top: 15px;
  }
  .content__downloads {
    /* background-color: pink; */
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute;
    margin: 0 0 50px 0; */
  }
  .card_download {
    width: 80vw !important;
    width: 80% !important;
    height: 70px !important;
    padding: 0px !important;
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
  }

  .card_porque_carteirinha img {
    height: auto;
    width: 30vw;
  }
  .card_porque_carteirinha {
    width: 250px;
  }
  .porque_subititulo {
    font-size: 20px;
  }
  .porque_subititulo {
    height: auto;
  }
  .img_economize img {
    width: 80vw;
  }
  .economize {
    margin: 25px 0;
  }

  .price__items {
    padding: 50px 0;
  }
  .content__app_img {
    margin: 0px 0 0 0;
  }
  .content__price .circle__section .price__items {
    height: auto;
  }
  .from__price span {
    padding: 0 0 0 25px;
    /* background-color: red; */
  }
  .circle__section {
    height: 40%;
    margin: 0 0 0 -50px;
  }
  /* .content__contagem_regressiva {
    left: 0;
    padding: 0 5rem 0 6.5rem;
  } */
  .content__downloads {
    left: 0;
    /* padding: 0 3rem 0 3.5rem; */
  }
  .card_download {
    justify-content: space-around;
  }
  .container_contagem {
    width: auto;
  }

  .titulo_contagem {
    font-size: 12px;
  }
  .info_contagem {
    font-size: 35px;
  }
  .info_contagem_detalhado {
    margin: -10px 0 2px 0;
  }
  .dado_principal_card_donwload {
    font-size: 28px;
    line-height: 30px;
  }
  .card_download {
    width: auto;
    padding: 0.5rem;
  }
  .descricao_card_download {
    font-size: 12px;
  }

  .txt_pergunta,
  .icone_add,
  .card_pergunta {
    height: 80px;
    margin: 0;
    padding: 0;
  }
  .paragrafo_lembre_1,
  .paragrafo_lembre_2,
  .btn_peca_carteirinha,
  .card_pergunta,
  .txt_resposta,
  .txt_meia_entrada,
  .txt_whats,
  .telefone_meia_entrada {
    width: 80vw;
  }
  .telefone_meia_entrada {
    margin-top: 25px;
  }
  .paragrafo_acao {
    width: 90vw;
    height: 20vh;
  }
  .img_carteirinha_info {
    background-size: 68vw;
  }
  .cards_acao {
    width: 80vw;
    flex-direction: column;
    margin: 0 0 -30px 0;
  }
  .card_acao,
  .card_acao_2 {
    align-self: auto;
    margin: 0 0 35px 0;
    width: 70vw;
    height: 15vh;
  }
  .paragrafo_card_acao {
    width: 60vw;
  }
  .card_acao {
    align-self: auto;
  }
  .solicite_cards {
    flex-direction: column;
  }
  .card_pergunta {
    /* background-color: #00d9f5; */
    align-items: flex-end;
  }
  .txt_pergunta,
  .txt_resposta {
    padding: 0;
    margin: 0;
  }
  .icone_add {
    min-width: 20px;
    background-position: center;
  }

  .banner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: unset;
  }
  .text__section {
    padding-top: unset !important;
    padding-left: unset !important;
    padding-right: unset !important;
  }
  .image__section {
    padding-bottom: unset !important;
  }
  .content__price .circle__section {
    width: 70%;
  }
  .content__student__card__example {
    left: 15%;
    width: 91%;
    top: 39%;
  }
  .titulo_banner {
    font-size: 2.25rem;
  }
  .subtitulo_banner {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.5rem;
  }
  .titulo_banner {
    font-size: 2rem;
    line-height: 1;
  }
  .content__infos h3 {
    margin-top: 0;
  }
  .valor_novo {
    font-size: 40px;
  }
  .banner {
    align-items: center;
    flex-direction: column;
  }
  .banner_txt,
  .btn_prosseguir {
    width: 100%;
    text-align: center;
  }
  .img_card {
    width: 80vw;
    margin: 0;
    margin-top: 150px;
  }
  .div_celular {
    flex-direction: column;
  }
  .div_img_celular {
    margin: 50px 0 0 0;
    width: auto;
  }
  .celular {
    width: 70vw;
  }
  .efeito_celular img {
    width: 60vw;
  }
  .txt_celular {
    text-align: center;
  }
  .flex {
    flex-direction: column;
  }
  .div_noticias {
    flex-direction: column;
    /* background-color: orange; */
    align-items: center;
  }

  .noticia_secundaria {
    width: auto;
    align-items: center;
    margin: 0;
  }
  .div_noticias {
    height: 1200px;
    justify-content: start;
  }
  .card_noticia_principal {
    width: 80vw;
    /* background-color: #00d9f5; */
    margin-bottom: 25px;
  }
  .card_noticia_secundaria {
    flex-direction: column;
    align-items: center;
    /* background-color: #00d9f5; */
    margin-bottom: 25px;
    width: 80vw;
  }
  .div_texto_celular {
    padding: 0;
  }
  .txt_card_noticia_secundaria {
    width: 80vw;
  }
  .simulacao_funcionalidade,
  .simulacao_funcionalidade div {
    width: 70vw;
  }
  .simulacao {
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-start; */
    align-items: center;
  }
  .simulacao_resultado {
    width: 80vw;
    text-align: center;
    align-items: center;
    justify-content: start;
    /* margin-top: 100px; */
  }
  .numeracao {
    padding: 0 10px;
  }
  .bg_fantasma {
    height: 3900px;
    /* 1150 + 150 + 500 + 400 + 450 + 800 +  500*/
  }
  .div_celular {
    height: 1150px;
  }
  .div_titulo_noticia {
    height: 150px;
  }
  .noticia_principal {
    width: auto;
    height: 500px;
  }
  .card_noticia_secundaria {
    height: 400px;
  }
  .noticia_secundaria {
    height: 850px;
  }
  .simulacao_funcionalidade {
    padding: 50px 0;
    height: 800px;
  }
  .simulacao_resultado {
    height: 500px;
  }
  .titulo_simule_div {
    height: 200px;
  }
  .card_simule {
    height: 300px;
  }

  .content__price .circle__section {
    width: 70%;
  }

  .content__student__card__example {
    top: 40%;
  }
}
@media screen and (orientation: portrait), (max-width: 400px) {
  .img_carteirinha_info {
    background-size: 88vw;
  }
  .card_acao {
    height: 22vh;
  }
  .paragrafo_acao {
    height: 28vh;
  }
  .paragrafo_card_acao {
    padding-top: 20px;
  }
  .card_solicite {
    height: 150px;
  }
  .img_icone_solicite,
  .img_icone_solicite div {
    width: 50px;
    height: 50px;
    background-size: 50px;
  }
  .prosseguir {
    font-size: 15px;
  }
  .titulo_simule {
    font-size: 30px;
    line-height: 1.5;
  }
  /* .div_noticias {
    height: 1300px;
  } */
  /* .simulacao {
    height: 1550px;
  } */
  .valor_soma_economizaria,
  .cifrao_economizaria {
    font-size: 50px;
  }
}

/** New Styles */
.content__section_1 {
  background-size: auto 100%;
  background-position: right center;
}

.content__section_2 {
  background-size: cover;
  background-position: top center;
  padding-top: 3rem;
  transform: translateY(-2px);
}
.titulo_banner {
  font-family: yummo, sans-serif;
}

/** End New Styles */
</style>
